/* eslint-disable react/react-in-jsx-scope */
import { Tooltip } from '@mui/material';
import {
  GridColumns, GridValueFormatterParams, GridValueGetterParams
} from '@mui/x-data-grid';
import dayjs from 'dayjs';
import './inquiryTable.types.scss';


export const inquiryListColumns: GridColumns = [
  {
    headerName: 'Name',
    field: 'name',
    flex: 1,
    cellClassName: 'main-column',
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1.25,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
    renderCell: (params) => (
      params.formattedValue !== '-' ? (
        <Tooltip title="Click to copy to clipboard." arrow>
          <span className="email">{params.formattedValue}</span>
        </Tooltip>
      ) : (
        <span className="email">{params.formattedValue}</span>
      )
    ),
  },
  {
    headerName: 'Phone Number',
    field: 'phoneNumber',
    flex: 0.75,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Category',
    field: 'type',
    flex: .75,
    valueGetter: (params: GridValueGetterParams) => params.row.type?.title || '-',
  },
  {
    headerName: 'Message',
    field: 'message',
    flex: 2,
    valueFormatter: (params: GridValueFormatterParams) => params.value || '-',
  },
  {
    headerName: 'Date',
    field: 'createdAt',
    flex: .5,
    valueFormatter: (params: GridValueFormatterParams) => (params.value) ? dayjs(params.value as string).format('MM/DD/YYYY') : '-',
  },
];
